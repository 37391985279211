import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CONNECTION_STATUS_NAME, DRAWER_KEY, EVENTS, PLAN_STATUS_KEY } from 'app-constants'
import { readMessage } from 'slices/customer'
import { setModal } from 'slices/modal'
import { setDrawer } from 'slices/drawer'
import { setServiceOrderMassive } from 'slices/serviceOrder'
import { formatDate, getFirstName, getMassiveMessage } from 'utils'
import { STATICS } from 'statics'
import { Card } from 'components/card'
import { Button } from 'components/button'
import './style.scss'

export function MessagesModal() {
  const { push } = useHistory()
  const dispatch = useDispatch()
  const [message, setMessage] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [items, setItems] = useState({})

  const { isMassive } = message
  const shouldOpenContactModal = useSelector(state => state.modal.shouldOpenContactModal)
  const { connectionStatus } = useSelector(state => state.contract)
  const { messages, info } = useSelector(({ customer }) => customer)
  const { contractStatus } = useSelector(state => state.contract.selected)
  const {
    virtualSupportMassive: { message: massiveWarning },
  } = useSelector(({ massive }) => massive)
  const idContract = useSelector(state => state.contract.selected.id)

  useEffect(() => {
    const array = []

    if (massiveWarning && contractStatus === PLAN_STATUS_KEY.ST_CONT_HABILITADO) {
      array.push(getMassiveMessage(massiveWarning))
    }

    if (messages?.contentUnread && !modalOpen) {
      messages.contentUnread.forEach(element => {
        if (element.mandatory) {
          array.push(element)
        }
      })

      setModalOpen(true)
      setItems(array)
    }
  }, [messages.contentUnread, modalOpen, idContract, massiveWarning, contractStatus])

  useEffect(() => {
    const massiveWarnings = {
      ...items[0],
      index: 0,
    }
    setMessage(massiveWarnings)
  }, [items])

  useEffect(() => {
    const { id, visualized } = message
    if (id && !visualized) {
      dispatch(readMessage(id))
    }
  }, [message, dispatch])

  function handleNextMessage() {
    const index = message.index + 1
    const newMessage = {
      ...items[index],
      index,
    }
    setMessage(newMessage)
  }

  function handleCloseClick() {
    push('/')

    if (shouldOpenContactModal) dispatch(setModal({ key: 'contact_update' }))
    else dispatch(setModal({ key: '' }))
  }

  function handleServiceOrderClick() {
    dispatch(setModal({ key: '' }))
    dispatch(setServiceOrderMassive(true))
    dispatch(setDrawer(DRAWER_KEY.VIRTUAL_SUPPORT))
  }

  function handleMassiveContinueClick() {
    return hasMoreMessages() ? handleNextMessage() : handleCloseClick()
  }

  function hasMoreMessages() {
    return items.length > message.index + 1
  }

  function isServiceOrderAvailableToSignal() {
    return (
      connectionStatus === CONNECTION_STATUS_NAME.BAD_SIGNAL ||
      connectionStatus === CONNECTION_STATUS_NAME.OFFLINE
    )
  }

  function renderMessageActions() {
    return hasMoreMessages() ? (
      <Button className='button' color='primary' size='large' onClick={() => handleNextMessage()}>
        Ok, próximo
      </Button>
    ) : (
      <Button className='button' color='primary' size='large' onClick={handleCloseClick}>
        Voltar a home
      </Button>
    )
  }

  function renderMassiveActions() {
    return (
      <>
        <Button className='button' size='large' outlined onClick={handleMassiveContinueClick}>
          Está tudo normal
        </Button>
        <Button className='button' size='large' color='primary' onClick={handleServiceOrderClick}>
          Sim, existe
        </Button>
      </>
    )
  }

  function renderActions() {
    if (isMassive) {
      if (!connectionStatus) return
      return isServiceOrderAvailableToSignal() ? renderMassiveActions() : renderMessageActions()
    }

    return renderMessageActions()
  }

  return (
    <div className='message-modal'>
      <h3 className='title'>
        Olá <span className='name'>{getFirstName(info.name)}</span>, você tem novas mensagens!
      </h3>
      <div className='message-details-component-modal'>
        <Card className='card'>
          <p className='title'>{message.title}</p>
          {message.imageUrl && <img alt={message.title} src={message.imageUrl} />}
          <p className='text description'>{message.description}</p>
          <div className='box-link'>
            {message.launchUrl && (
              <a
                className='link'
                rel='noopener noreferrer'
                href={message.launchUrl}
                target='_blank'
              >
                {' '}
                {message.launchUrl}
              </a>
            )}
          </div>
          <div className='box'>
            <p className='date text'>{formatDate(message.creationDateTime)}</p>
          </div>
        </Card>
        {hasMoreMessages() && (
          <div>
            <STATICS.ARROW_RIGHT className='icon' onClick={() => handleNextMessage()} />
          </div>
        )}
      </div>
      <div className='message-actions'>{renderActions()}</div>
    </div>
  )
}
