import { TV_PLAN_CARD_VALUES, TV_PLAN_STATUS, TV_PLAN_STATUS_LABEL } from 'app-constants'
import { getTvActivationLink } from 'hooks'

async function getTvLink(contractId, productId, itemCode) {
  try {
    const params = { contractId, productId, productName: itemCode }
    const { data } = await getTvActivationLink(params)

    return { tvPlanKey: itemCode, activationLink: data.activationLink }
  } catch (error) {
    console.error(error)

    return { tvPlanKey: itemCode, activationLink: null }
  }
}

export async function getTvLinks(contractId, additionals = []) {
  const tvPlans = additionals.filter(({ itemCode }) => TV_PLAN_CARD_VALUES.includes(itemCode))
  const requests = []

  // eslint-disable-next-line no-restricted-syntax
  for (const tvPlan of tvPlans) {
    const { productId, itemCode } = tvPlan

    requests.push(getTvLink(contractId, productId, itemCode))
  }

  return Promise.all(requests)
}

export function isTvPlanActive(tvPlan) {
  return tvPlan.status === TV_PLAN_STATUS.ACTIVE
}

export function getTvPlanButtonLabel(tvPlan) {
  return isTvPlanActive(tvPlan)
    ? TV_PLAN_STATUS_LABEL[TV_PLAN_STATUS.ACTIVE]
    : TV_PLAN_STATUS_LABEL[TV_PLAN_STATUS.INACTIVE]
}
